.question-type__form_example{
  padding: 20px;
}

.question-type__header_example{
  font-weight: bold;
  font-size: 20px;
  padding-left:  20px;
}

.question-type__icon_volume_example{
  font-size: 20px;
  margin-right: 10px;
}